declare var require: any
import React from 'react';

import Keycloak from 'keycloak-js';
import * as set from './settings';
import { Text } from '@consta/uikit/Text';
//import keycloak from './src/keycloak';
import { ReactKeycloakProvider } from "@react-keycloak/web";

import './src/i18n';

import { Page } from './src/Page';
var ReactDOM = require('react-dom');

var keycloak = null;
const App = () => {
    const [loadStatus, setLoadStatus] = React.useState(true);

    React.useEffect(() => {
        getKeycloakParams();
    }, []);

    const getKeycloakParams = () => {
        fetch(set.api.keycloakParams, {
            method: "GET",
            headers: {
                'Access-Control-Allow-Origin': '*'
            },
        }).then((response) => {
            response.json().then((data) => {
                const json = {
                    url: data["auth-server-url"],
                    realm: data["realm"],
                    clientId: data["resource"]
                }
                keycloak = Keycloak(json);
                setLoadStatus(false);
            })
        })
    }

    return (
        <>
            {
                loadStatus ?
                    <Text className={'nameLine'} align="center" weight="bold">СОЕДИНЕНИЕ С СЕРВЕРОМ</Text> :
                    <React.Suspense fallback='loading'>
                        <ReactKeycloakProvider
                            authClient={keycloak}
                            onTokens={({ token }) => {
                                localStorage.setItem("keycloakToken", token);
                            }}
                        >
                            <Page />
                        </ReactKeycloakProvider>  
                    </React.Suspense>
                    
            }
        </>
    )
       
};

ReactDOM.render(<App />, document.getElementById("root"));

/*if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('./service-worker.js')
            .then(registration => {
                console.log('SW registered: ', registration);
            }).catch(registrationError => {
                console.log('SW registration failed: ', registrationError);
            });
    });
}*/