// @ts-chwqeckqwe
import React from 'react'
import { HeaderLogin } from '../Login/HeaderLogin';
import {
	Header,
	HeaderModule,
	HeaderLogo,
	HeaderMenu,
	HeaderSearchBar
} from '@consta/uikit/Header';
import { TileMenu } from '@consta/header/TileMenu';
import { SNGSLogo } from '../../public/images/sngs_logo';
import { Text } from '@consta/uikit/Text';
import { ThemeToggler } from '@consta/uikit/ThemeToggler';
import { cn } from '@bem-react/classname';
import { useTranslation } from 'react-i18next';
import { MobileMenu } from '../MobileMenu';
import { Button } from '@consta/uikit/Button';

export default function NavBar({breakpoints, favorite, publ, dev, plan, setPlan, setDev, setPublic, setFavorite, selMenuItem, menuItems, isMobile, keycloak, userLocalInfo, handleLogin, buttonRef, tileMenu, searchValue, lang, langItem, themeItems, themeValue, setLang, setThemeValue, setSearchValue}: any) {
	const cnHeader = cn('HeaderUnofactor');
    const cnHeaderMobile = cn('HeaderUnofactorMobile');
    const cnMobileMenuUno = cn('MobileMenuUno');

	const { t, i18n } = useTranslation();
    console.log(breakpoints)

    if (breakpoints.l) {
        return (
                    <Header
                        className={cnHeader()}
                        leftSide={
                            <>
                                <HeaderModule indent="s">
                                    <HeaderLogo>
                                        <div style={{ width: "52px", height: "52px", fill: "var(--color-bg-brand)" }}>
                                            <SNGSLogo />
                                        </div>
                                    </HeaderLogo>
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <Text size="m" weight="bold" transform="uppercase">
                                        {t('Header.MainTitlePart1')}
                                    </Text>
                                    <Text as="div" size="m" weight="bold" transform="uppercase">
                                        {t('Header.MainTitlePart2')}<Text as="span" size="xs" style={{ verticalAlign: 'super' }}>{'\u24C7'}</Text>
                                    </Text>
                                </HeaderModule>
                                <HeaderModule indent="l">
                                    <div style={{display: 'flex'}}>
                                        <Button
                                            style={{ background: favorite ? "#0078d2" : "#E7E7E7", color: favorite ? "white" : "black" }}
                                            size="xs" form="round" label={'#' + t("Filter.favorite")}
                                            onClick={() => { localStorage.setItem("favoriteFilter", (!favorite).toString()); setFavorite(!favorite); }}
                                        />
                                        <Button
                                            style={{ background: publ ? "#0078d2" : "#E7E7E7", color: publ ? "white" : "black", marginLeft: "10px" }}
                                            size="xs" form="round" label={'#' + t("Filter.public")}
                                            onClick={() => { localStorage.setItem("publicFilter", (!publ).toString()); setPublic(!publ); }}
                                        />
                                        <Button
                                            style={{ background: dev ? "#0078d2" : "#E7E7E7", color: dev ? "white" : "black", marginLeft: "10px" }}
                                            size="xs" form="round" label={'#' + t("Filter.developing")}
                                            onClick={() => { localStorage.setItem("devFilter", (!dev).toString()); setDev(!dev); }}
                                        />
                                        <Button
                                            style={{ background: plan ? "#0078d2" : "#E7E7E7", color: plan ? "white" : "black", marginLeft: "10px" }}
                                            size="xs" form="round" label={'#' + t("Filter.planning")}
                                            onClick={() => { localStorage.setItem("planFilter", (!plan).toString()); setPlan(!plan); }}
                                        />
                                    </div>
                                </HeaderModule>
                            </>
                        }
                        rightSide={
                            <>
                                <HeaderModule indent="s">
                                    <HeaderSearchBar
                                        placeholder={t("Header.SearchService")}
                                        label="поиск"
                                        value={searchValue}
                                        onChange={(value) => { localStorage.setItem("searchValue", value.value); setSearchValue(value.value); }}
                                    />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <ThemeToggler
                                        size="m"
                                        items={langItem}
                                        value={lang}
                                        getItemKey={(item) => item.label}
                                        getItemLabel={(item) => item.label}
                                        getItemIcon={(item) => item.icon}
                                        onChange={({ value }) => { localStorage.setItem("Lang", JSON.stringify(value));; setLang(value); i18n.changeLanguage(value.lang) }}
                                    />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <ThemeToggler
                                        size="m"
                                        items={themeItems}
                                        getItemKey={(item) => item.label}
                                        getItemLabel={(item) => item.label}
                                        getItemIcon={(item) => item.icon}
                                        value={themeValue}
                                        onChange={({ value }) => { localStorage.setItem("mainTheme", JSON.stringify(value)); setThemeValue(value) }}
                                        direction="downStartLeft"
                                    />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <TileMenu items={tileMenu} title={'Дополнительно'} />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <HeaderLogin
                                        authorized={keycloak.authenticated}
                                        personName={userLocalInfo.personName}
                                        personInfo={userLocalInfo.personInfo}
                                        personAvatarUrl={userLocalInfo.personAvatarUrl}
                                        onClick={handleLogin}
                                        ref={buttonRef}
                                    />
                                </HeaderModule>
                            </>
                        }
                    />
        )
    }

    if (breakpoints.m){
        return (
                <Header
                        className={cnHeader()}
                        leftSide={
                            <>
                                <HeaderModule indent="s">
                                    <HeaderLogo>
                                        <div style={{ width: "52px", height: "52px", fill: "var(--color-bg-brand)" }}>
                                            <SNGSLogo />
                                        </div>
                                    </HeaderLogo>
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <Text size="s" weight="bold" transform="uppercase">
                                        {t('Header.MainTitlePart1')}
                                    </Text>
                                    <Text as="div" size="s" weight="bold" transform="uppercase">
                                        {t('Header.MainTitlePart2')}<Text as="span" size="xs" style={{ verticalAlign: 'super' }}>{'\u24C7'}</Text>
                                    </Text>
                                </HeaderModule>
                            </>
                        }
                        rightSide={
                            <>
                                <HeaderModule indent="s">
                                    <HeaderSearchBar
                                        placeholder={t("Header.SearchService")}
                                        label="поиск"
                                        value={searchValue}
                                        onChange={(value) => { localStorage.setItem("searchValue", value.value); setSearchValue(value.value); }}
                                    />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <ThemeToggler
                                        size="m"
                                        items={langItem}
                                        value={lang}
                                        getItemKey={(item) => item.label}
                                        getItemLabel={(item) => item.label}
                                        getItemIcon={(item) => item.icon}
                                        onChange={({ value }) => { localStorage.setItem("Lang", JSON.stringify(value));; setLang(value); i18n.changeLanguage(value.lang) }}
                                    />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <ThemeToggler
                                        size="m"
                                        items={themeItems}
                                        getItemKey={(item) => item.label}
                                        getItemLabel={(item) => item.label}
                                        getItemIcon={(item) => item.icon}
                                        value={themeValue}
                                        onChange={({ value }) => { localStorage.setItem("mainTheme", JSON.stringify(value)); setThemeValue(value) }}
                                        direction="downStartLeft"
                                    />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <TileMenu items={tileMenu} title={'Дополнительно'} />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <HeaderLogin
                                        authorized={keycloak.authenticated}
                                        personName={userLocalInfo.personName}
                                        personInfo={userLocalInfo.personInfo}
                                        personAvatarUrl={userLocalInfo.personAvatarUrl}
                                        onClick={handleLogin}
                                        ref={buttonRef}
                                        isMinified
                                    />
                                </HeaderModule>
                            </>
                        }
                    />
        );
    }

    if (breakpoints.s) {
        return (
                <Header
                    className={cnHeaderMobile()}
                    leftSide={
                        <>
                            <HeaderModule indent="s" >
                                <MobileMenu
                                    className={cnMobileMenuUno('Menu')}
                                    items={menuItems.map((itm, index) => {
                                        itm.active = itm.label == selMenuItem;
                                        return itm;
                                    })}
                                    setFavorite={setFavorite}
                                    setPublic={setPublic}
                                    setDev={setDev}
                                    setPlan={setPlan}
                                    plan={plan}
                                    dev={dev}
                                    publ={publ}
                                    favorite={favorite}
                                    header={
                                        <div className={cnMobileMenuUno('MobileMenuHeader')}>
                                            <div>
                                                <HeaderLogin
                                                    authorized={keycloak.authenticated}
                                                    personName={userLocalInfo.personName}
                                                    personInfo={userLocalInfo.personInfo}
                                                    personAvatarUrl={userLocalInfo.personAvatarUrl}
                                                    onClick={handleLogin}
                                                    ref={buttonRef}
                                                />
                                            </div>
                                            <div>
                                                <ThemeToggler
                                                    size="s"
                                                    items={langItem}
                                                    value={lang}
                                                    getItemKey={(item) => item.label}
                                                    getItemLabel={(item) => item.label}
                                                    getItemIcon={(item) => item.icon}
                                                    onChange={({ value }) => { localStorage.setItem("Lang", JSON.stringify(value));; setLang(value); i18n.changeLanguage(value.lang) }}
                                                />
                                                <ThemeToggler
                                                    size="s"
                                                    items={themeItems}
                                                    value={themeValue}
                                                    getItemKey={(item) => item.label}
                                                    getItemLabel={(item) => item.label}
                                                    getItemIcon={(item) => item.icon}
                                                    onChange={({ value }) => { localStorage.setItem("mainTheme", JSON.stringify(value)); setThemeValue(value) }}
                                                />
                                                <TileMenu items={tileMenu} title={'Дополнительно'} isMobile style={{ zIndex: "105" }} />
                                            </div>
                                            <div className={cnMobileMenuUno('MobileSearchContainer')}>
                                                <HeaderSearchBar
                                                    className={cnMobileMenuUno('MobileSearchContainer')}
                                                    placeholder={t("Header.SearchService")}
                                                    label="поиск"
                                                    value={searchValue}
                                                    onChange={(value) => { localStorage.setItem("searchValue", value.value); setSearchValue(value.value); }}
                                                />
                                            </div>
                                        </div>
                                    }
                                />
                            </HeaderModule>
                            <HeaderModule indent="s">
                                <HeaderLogo>
                                    <div style={{ width: "52px", height: "52px", fill: "var(--color-bg-brand)" }}>
                                        <SNGSLogo />
                                    </div>
                                </HeaderLogo>
                            </HeaderModule>
                            <HeaderModule indent="s">
                                <Text size="s" weight="bold" transform="uppercase">
                                    {t('Header.MainTitlePart1')}
                                </Text>
                                <Text as="div" size="s" weight="bold" transform="uppercase">
                                    {t('Header.MainTitlePart2')}<Text as="span" size="2xs" style={{ verticalAlign: 'super' }}>{'\u24C7'}</Text>
                                </Text>
                                
                            </HeaderModule>
                        </>
                    }
                />
        );
    }

    if (isMobile) {
        return (
                    <Header
                        className={cnHeaderMobile()}
                        leftSide={
                            <>
                                <HeaderModule indent="s" >
                                    <MobileMenu
                                        className={cnMobileMenuUno('Menu')}
                                        items={
                                            menuItems.map((itm, index) => {
                                                itm.active = itm.label == selMenuItem;
                                                return itm;
                                            })
                                        }
                                        setFavorite={setFavorite}
                                        setPublic={setPublic}
                                        setDev={setDev}
                                        setPlan={setPlan}
                                        plan={plan}
                                        dev={dev}
                                        publ={publ}
                                        favorite={favorite}
                                        header={
                                            <div className={cnMobileMenuUno('MobileMenuHeader')}>
                                                <div>
                                                    <HeaderLogin
                                                        authorized={keycloak.authenticated}
                                                        personName={userLocalInfo.personName}
                                                        personInfo={userLocalInfo.personInfo}
                                                        personAvatarUrl={userLocalInfo.personAvatarUrl}
                                                        onClick={handleLogin}
                                                        ref={buttonRef}
                                                    />
                                                </div>
                                                <div>
                                                    <ThemeToggler
                                                        size="s"
                                                        items={langItem}
                                                        value={lang}
                                                        getItemKey={(item) => item.label}
                                                        getItemLabel={(item) => item.label}
                                                        getItemIcon={(item) => item.icon}
                                                        onChange={({ value }) => { localStorage.setItem("Lang", JSON.stringify(value)); setLang(value); i18n.changeLanguage(value.lang) }}
                                                    />
                                                    <ThemeToggler
                                                        size="s"
                                                        items={themeItems}
                                                        value={themeValue}
                                                        getItemKey={(item) => item.label}
                                                        getItemLabel={(item) => item.label}
                                                        getItemIcon={(item) => item.icon}
                                                        onChange={({ value }) => { localStorage.setItem("mainTheme", JSON.stringify(value)); setThemeValue(value) }}
                                                    />
                                                    <TileMenu items={tileMenu} title={'Дополнительно'} isMobile style={{ zIndex: "105" }} />
                                                </div>
                                                <div className={cnMobileMenuUno('MobileSearchContainer')}>
                                                    <HeaderSearchBar
                                                        className={cnMobileMenuUno('MobileSearchContainer')}
                                                        placeholder={t("Header.SearchService")}
                                                        label={t("Header.Search")}
                                                        value={searchValue}
                                                        onChange={(value) => { localStorage.setItem("searchValue", value.value); setSearchValue(value.value); }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <HeaderLogo>
                                        <div style={{ width: "52px", height: "52px", fill: "var(--color-bg-brand)" }}>
                                            <SNGSLogo />
                                        </div>
                                    </HeaderLogo>
                                </HeaderModule>
                                <HeaderModule indent="s">
                                    <Text size="s" weight="bold" transform="uppercase">
                                        {t('Header.MainTitlePart1')}
                                    </Text>
                                    <Text as="div" size="s" weight="bold" transform="uppercase">
                                        {t('Header.MainTitlePart2')}<Text as="span" size="2xs" style={{ verticalAlign: 'super' }}>{'\u24C7'}</Text>
                                    </Text>
                                </HeaderModule>
                            </>
                        }
                    />
        );
    }

    return (
        <Header
            className={cnHeaderMobile()}
            leftSide={
                <>
                    <HeaderModule indent="s" >
                        <MobileMenu
                            className={cnMobileMenuUno('Menu')}
                            items={menuItems.map((itm, index) => {
                                itm.active = itm.label == selMenuItem;
                                return itm;
                            })}
                            setFavorite={setFavorite}
                            setPublic={setPublic}
                            setDev={setDev}
                            setPlan={setPlan}
                            plan={plan}
                            dev={dev}
                            publ={publ}
                            favorite={favorite}
                            header={
                                <div className={cnMobileMenuUno('MobileMenuHeader')}>
                                    <div>
                                        <HeaderLogin
                                            authorized={keycloak.authenticated}
                                            personName={userLocalInfo.personName}
                                            personInfo={userLocalInfo.personInfo}
                                            personAvatarUrl={userLocalInfo.personAvatarUrl}
                                            onClick={handleLogin}
                                            ref={buttonRef}
                                        />
                                    </div>
                                    <div>
                                        <ThemeToggler
                                            size="s"
                                            items={langItem}
                                            value={lang}
                                            getItemKey={(item) => item.label}
                                            getItemLabel={(item) => item.label}
                                            getItemIcon={(item) => item.icon}
                                            onChange={({ value }) => { localStorage.setItem("Lang", JSON.stringify(value));; setLang(value); i18n.changeLanguage(value.lang) }}
                                        />
                                        <ThemeToggler
                                            size="s"
                                            items={themeItems}
                                            value={themeValue}
                                            getItemKey={(item) => item.label}
                                            getItemLabel={(item) => item.label}
                                            getItemIcon={(item) => item.icon}
                                            onChange={({ value }) => { localStorage.setItem("mainTheme", JSON.stringify(value)); setThemeValue(value) }}
                                        />
                                        <TileMenu items={tileMenu} title={'Дополнительно'} isMobile style={{ zIndex: "105" }} />
                                    </div>
                                    <div className={cnMobileMenuUno('MobileSearchContainer')}>
                                        <HeaderSearchBar
                                            className={cnMobileMenuUno('MobileSearchContainer')}
                                            placeholder={t("Header.SearchService")}
                                            label="поиск"
                                            value={searchValue}
                                            onChange={(value) => { localStorage.setItem("searchValue", value.value); setSearchValue(value.value); }}
                                        />
                                    </div>
                                </div>
                            }
                        />
                    </HeaderModule>
                    <HeaderModule indent="s">
                        <HeaderLogo>
                            <div style={{ width: "52px", height: "52px", fill: "var(--color-bg-brand)" }}>
                                <SNGSLogo />
                            </div>
                        </HeaderLogo>
                    </HeaderModule>
                    <HeaderModule indent="s">
                        <Text size="s" weight="bold" transform="uppercase">
                            {t('Header.MainTitlePart1')}
                        </Text>
                        <Text as="div" size="s" weight="bold" transform="uppercase">
                            {t('Header.MainTitlePart2')}<Text as="span" size="2xs" style={{ verticalAlign: 'super' }}>{'\u24C7'}</Text>
                        </Text>
                        
                    </HeaderModule>
                </>
            }
        />
);
}
