module.exports = {
	page: {
		title: "UnoWorld",
		favicon: "favicon.png",
		tabicon: {
			icon: "images/sngs-icon-black.svg",
			colour: "#8f0000"
		},
	},
	header: {
		title: 'Микросервисная цифровая платформа Унофактор\u24C7',
		image: "images/sngs_grad.svg",
		image_light: "images/sngs_grad.svg"
	},
	api: {
		categoriesApi: "",
		keycloakParams: "/api/keycloak"
    }
}