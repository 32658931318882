import React from 'react'
import { Sidebar } from '@consta/uikit/Sidebar'
import { cn } from '@bem-react/classname';
import { User } from '@consta/uikit/User';
import { Button } from '@consta/uikit/Button';
import { useTranslation } from 'react-i18next';
import { VerticalMenu } from '@consta/header/VerticalMenu';
import { Transition } from 'react-transition-group';
import { Popover, Direction } from '@consta/uikit/Popover';
import { cnMixPopoverAnimate, animateTimeout } from '@consta/uikit/MixPopoverAnimate'
import { cnMixSpace } from '@consta/uikit/MixSpace';


type Props = {
	direction?: Direction
}

type CnMixPopoverArrow = (
	elemNameOrBlockMods?: Props | string | null,
	elemModsOrBlockMix?: Props | Array<string | undefined> | null,
	elemMix?: Array<string | undefined>
) => string

export default function Side({setPlan, setDev, setPublic, setFavorite, publ, favorite, plan, dev, off, setDirection, buttonRef, direction, animateTimeout, selMenuItem, menuItems, keycloak, userLocalInfo, visibleLogoMenu, breakpoints, isMobile}) {
	const cnTileMenuListUnofactor = cn('TileMenuListUnofactor');
	const { t, i18n } = useTranslation();
const cnLoginMenu = cn('LoginMenu');
const cnMixPopoverArrow: CnMixPopoverArrow = cn('MixPopoverArrow')
const ARROW_SIZE = 6;
const ARROW_OFFSET = 10;
console.log(breakpoints, '12121212')

	if (isMobile) {
		<Sidebar
                        isOpen={visibleLogoMenu}
                    >
                        <div className={cnTileMenuListUnofactor({ view: "lines" }, [cnMixSpace({ p: 'xl' })])}>
                            <User
                                avatarUrl={userLocalInfo.personAvatarUrl}
                                name={userLocalInfo.personName}
                                info={userLocalInfo.personInfo}
                                width="full"
                            />
                            <Button
                                label={t("Header.Logout")}
                                style={{ marginTop: "var(--space-s)" }}
                                onClick={() => { keycloak.logout() }}
                            />
                        </div>
                    </Sidebar>
	}
	if (breakpoints.l) {
		return (
		<>
		<Sidebar
                        hasOverlay={false}
                        position="left"
                        isOpen={true}
                        size="s"
                        style={{ top: "60px", height: "calc(100% - 59px)" }}
                    > 
                        <VerticalMenu items={menuItems.map((itm, index) => {
                        itm.active = itm.label == selMenuItem;
                        return itm;
                        })} />
    
                    </Sidebar>
                    <Transition timeout={animateTimeout} unmountOnExit in={visibleLogoMenu}>
                        {animate => (
                            <Popover
                                className={cnLoginMenu('Popover', { view: 'cards' }, [
                                    cnMixPopoverAnimate({ animate, direction }),
                                ])}
                                anchorRef={buttonRef}
                                arrowOffset={ARROW_OFFSET + ARROW_SIZE}
                                offset={ARROW_SIZE + 4}
                                onSetDirection={setDirection}
                                onClickOutside={off}
                                style={{
                                    ['--popover-arrow-size' as string]: `${ARROW_SIZE}px`,
                                    ['--popover-arrow-offset' as string]: `${ARROW_OFFSET}px`,
                                    zIndex: 102,
                                }}
                            >
                                <div className={cnMixPopoverArrow({ direction })} />
                                <div className={cnTileMenuListUnofactor({ view: "lines" }, [cnMixSpace({ p: 'xl' })])}>
                                    <div className={cnLoginMenu('ListWrapper')}>
                                        <User
                                            avatarUrl={userLocalInfo.personAvatarUrl}
                                            name={userLocalInfo.personName}
                                            info={userLocalInfo.personInfo}
                                            width="full"
                                        />
                                        <Button
                                            label={t("Header.Logout")}
                                            style={{ marginTop: "var(--space-s)" }}
                                            onClick={() => { keycloak.logout(); }}
                                        />
                                    </div>
                                </div>
                            </Popover>
                        )}
                    </Transition>
		</>
		)
	}

	if (breakpoints.m) {
		return(
			<>
			<Sidebar
			hasOverlay={false}
			position="left"
			isOpen={true}
			size="s"
			style={{ top: "60px", height: "calc(100% - 59px)" }}
	> 
			<VerticalMenu items={menuItems.map((itm, index) => {
			itm.active = itm.label == selMenuItem;
			return itm;
			})} />
			<div 
				style={{ 
					margin: '10px 0 10px',
					width: '100%',
					position: 'absolute',
					bottom: '0',
					display: 'flex',
					borderTop: '1px solid var(--color-bg-border)',
					flexWrap: 'wrap',
					justifyContent: 'center'
				}}
			>
				<Button
						style={{ background: favorite ? "#0078d2" : "#E7E7E7", color: favorite ? "white" : "black", margin: '5px' }}
						size="xs" form="round" label={'#' + t("Filter.favorite")}
						onClick={() => { localStorage.setItem("favoriteFilter", (!favorite).toString()); setFavorite(!favorite); }}
				/>
				<Button
						style={{ background: publ ? "#0078d2" : "#E7E7E7", color: publ ? "white" : "black", margin: '5px'}}
						size="xs" form="round" label={'#' + t("Filter.public")}
						onClick={() => { localStorage.setItem("publicFilter", (!publ).toString()); setPublic(!publ); }}
				/>
				<Button
						style={{ background: dev ? "#0078d2" : "#E7E7E7", color: dev ? "white" : "black", margin: '5px'}}
						size="xs" form="round" label={'#' + t("Filter.developing")}
						onClick={() => { localStorage.setItem("devFilter", (!dev).toString()); setDev(!dev); }}
				/>
				<Button
						style={{ background: plan ? "#0078d2" : "#E7E7E7", color: plan ? "white" : "black", margin: '5px'}}
						size="xs" form="round" label={'#' + t("Filter.developing")}
						onClick={() => { localStorage.setItem("planFilter", (!plan).toString()); setPlan(!plan); }}
				/>
			</div>
	</Sidebar>
	<Transition timeout={animateTimeout} unmountOnExit in={visibleLogoMenu}>
			{animate => (
					<Popover
							className={cnLoginMenu('Popover', { view: 'cards' }, [
									cnMixPopoverAnimate({ animate, direction }),
							])}
							anchorRef={buttonRef}
							arrowOffset={ARROW_OFFSET + ARROW_SIZE}
							offset={ARROW_SIZE + 4}
							onSetDirection={setDirection}
							onClickOutside={off}
							style={{
									['--popover-arrow-size' as string]: `${ARROW_SIZE}px`,
									['--popover-arrow-offset' as string]: `${ARROW_OFFSET}px`,
									zIndex: 102,
							}}
					>
							<div className={cnMixPopoverArrow({ direction })} />
							<div className={cnTileMenuListUnofactor({ view: "lines" }, [cnMixSpace({ p: 'xl' })])}>
									<div className={cnLoginMenu('ListWrapper')}>
											<User
													avatarUrl={userLocalInfo.personAvatarUrl}
													name={userLocalInfo.personName}
													info={userLocalInfo.personInfo}
													width="full"
											/>
											<Button
													label={t("Header.Logout")}
													style={{ marginTop: "var(--space-s)" }}
													onClick={() => { keycloak.logout(); }}
											/>
									</div>
							</div>
					</Popover>
			)}
	</Transition>
	</>
		)
	}

	if (breakpoints.s) {
		return(
		<>
		<Sidebar
                    isOpen={visibleLogoMenu}
                    onClickOutside={off}
                    onClose={off}
                >
                    <div className={cnTileMenuListUnofactor({ view: "lines" }, [cnMixSpace({ p: 'xl' })])}>
                        <User
                            avatarUrl={userLocalInfo.personAvatarUrl}
                            name={userLocalInfo.personName}
                            info={userLocalInfo.personInfo}
                            width="full"
                        />
                        <Button
                            label={t("Header.Logout")}
                            style={{ marginTop: "var(--space-s)" }}
                            onClick={() => { keycloak.logout() }}
                        />
                    </div>
                </Sidebar>
								</>
		)
	}

		return(
		<>
		<Sidebar
                    isOpen={visibleLogoMenu}
                    onClickOutside={off}
                    onClose={off}
                >
                    <div className={cnTileMenuListUnofactor({ view: "lines" }, [cnMixSpace({ p: 'xl' })])}>
                        <User
                            avatarUrl={userLocalInfo.personAvatarUrl}
                            name={userLocalInfo.personName}
                            info={userLocalInfo.personInfo}
                            width="full"
                        />
                        <Button
                            label={t("Header.Logout")}
                            style={{ marginTop: "var(--space-s)" }}
                            onClick={() => { keycloak.logout() }}
                        />
                    </div>
                </Sidebar>
								</>
	)

}
