import React, { forwardRef } from 'react'

import { cn } from '@bem-react/classname';

import { MobileMenuProps, MobileMenuComponent } from './MobileMenuTypes'

import { VerticalMenu } from '@consta/header/VerticalMenu';
import { Sidebar } from '@consta/uikit/Sidebar'

import { Button } from '@consta/uikit/Button'

import { IconHamburger } from '@consta/uikit/IconHamburger'
import { useFlag } from '@consta/uikit/useFlag'

import './MobileMenu.css'
import { useTranslation } from 'react-i18next';

export const cnMobileMenu = cn('MobileMenu')

const MobileMenuRender = (props: MobileMenuProps, ref: React.Ref<HTMLButtonElement>) => {
  const {
    items,
    className,
    getItemActive,
    getItemHref,
    getItemLabel,
    getItemOnClick,
    getItemTarget,
    getItemSubMenu,
    header,
    onItemClick,
    sidebarClassName,
    footer,
    favorite,
    publ,
    dev,
    plan,
    setFavorite,
    setPublic,
    setDev,
    setPlan,
    ...otherProps
  } = props

  const { t, i18n } = useTranslation();

  const [visibleMenu, { toogle, off }] = useFlag()

  const elementZIndex = typeof props.style?.zIndex === 'number' ? props.style.zIndex + 1 : undefined

  return (
    <>
      <Button
        {...otherProps}
        size="m"
        view="clear"
        className={cnMobileMenu(null, [className])}
        iconLeft={IconHamburger}
        ref={ref}
        onClick={toogle}
      />
      <Sidebar
        className={cnMobileMenu('Sidebar', [sidebarClassName])}
        position="left"
        isOpen={visibleMenu}
        onClickOutside={off}
        size="m"
        style={{ zIndex: elementZIndex }}
      >
        <VerticalMenu
          className={cnMobileMenu('Menu')}
          items={items}
          getItemActive={getItemActive}
          getItemHref={getItemHref}
          getItemLabel={getItemLabel}
          getItemOnClick={getItemOnClick}
          getItemTarget={getItemTarget}
          getItemSubMenu={getItemSubMenu}
                  onItemClick={() => { onItemClick; off(); }}
          header={header}
          footer={footer}
        />
        <div 
          style={{ 
            margin: '10px 0 10px',
            width: '100%',
            position: 'absolute',
            bottom: '0',
            display: 'flex',
            borderTop: '1px solid var(--color-bg-border)',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}
        >
          <Button
              style={{ background: favorite ? "#0078d2" : "#E7E7E7", color: favorite ? "white" : "black", margin: '5px' }}
              size="xs" form="round" label={'#' + t("Filter.favorite")}
              onClick={() => { localStorage.setItem("favoriteFilter", (!favorite).toString()); setFavorite(!favorite); }}
          />
          <Button
              style={{ background: publ ? "#0078d2" : "#E7E7E7", color: publ ? "white" : "black", margin: '5px'}}
              size="xs" form="round" label={'#' + t("Filter.public")}
              onClick={() => { localStorage.setItem("publicFilter", (!publ).toString()); setPublic(!publ); }}
          />
          <Button
              style={{ background: dev ? "#0078d2" : "#E7E7E7", color: dev ? "white" : "black", margin: '5px'}}
              size="xs" form="round" label={'#' + t("Filter.developing")}
              onClick={() => { localStorage.setItem("devFilter", (!dev).toString()); setDev(!dev); }}
          />
          <Button
              style={{ background: plan ? "#0078d2" : "#E7E7E7", color: plan ? "white" : "black", margin: '5px'}}
              size="xs" form="round" label={'#' + t("Filter.developing")}
              onClick={() => { localStorage.setItem("planFilter", (!plan).toString()); setPlan(!plan); }}
          />
        </div>
      </Sidebar>
    </>
  )
}

export const MobileMenu = forwardRef(MobileMenuRender) as MobileMenuComponent

export * from './MobileMenuTypes'
