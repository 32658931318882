declare var require: any
import React from 'react';

import { useKeycloak } from "@react-keycloak/web";

import { cn } from '@bem-react/classname';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import {
    Theme,
    presetGpnDark,
    presetGpnDefault,
    presetGpnDisplay,
    ThemePreset
} from '@consta/uikit/Theme';

import { Text } from '@consta/uikit/Text';
import { useBreakpoints } from '@consta/uikit/useBreakpoints';
import { useFlag } from '@consta/uikit/useFlag';
import { animateTimeout } from '@consta/uikit/MixPopoverAnimate'
import { Direction } from '@consta/uikit/Popover';

/** Иконки */
import { IconComponent } from '@consta/icons/Icon';
import { IconSun } from '@consta/icons/IconSun';
import { IconMoon } from '@consta/icons/IconMoon';
import { IconLightningBolt } from '@consta/icons/IconLightningBolt';
import { IconRus } from './icons/IconRus';
import { IconEn } from './icons/IconEn';
import { CategoryRow } from './Categories';

import { isMobile } from 'react-device-detect';

import "../style.css";
import NavBar from './Components/NavBar'
import Side from './Components/Side'
import Main from './Components/Main'

type TileMenuItem = {
    label: string
    description: React.ReactNode
    image: string
    href: string
}


export type ThemeType = {
    label: string;
    theme: ThemePreset;
    icon: IconComponent;
};

export type LangItemType = {
    label: string;
    icon: IconComponent;
    lang: string;
};

export const langItem: LangItemType[] = [
    {
        label: 'Русский',
        icon: IconRus,
        lang: 'ru-RU'
    },
    {
        label: 'Английский',
        icon: IconEn,
        lang: 'en'
    }
];

type Props = {
    direction?: Direction
}
type CnMixPopoverArrow = (
    elemNameOrBlockMods?: Props | string | null,
    elemModsOrBlockMix?: Props | Array<string | undefined> | null,
    elemMix?: Array<string | undefined>
) => string


const cnRootTheme = cn('RootTheme');


type UserItem = {
    personName: string,
    personInfo: string,
    personStatus?: "available" | "remote" | "out" | undefined;
    personAvatarUrl: string,
}

var defaultUser: UserItem = {
    personName: '',
    personInfo: '',
    personStatus: "available",
    personAvatarUrl: "",
}

var keycloakGroups = [];

var favoriteList = localStorage.getItem('favoriteList') != null && localStorage.getItem('favoriteList') != "null" ? JSON.parse(localStorage.getItem('favoriteList')) : {}
export const Page = () => {

    const [showVerticalMenu, setShowVerticalMenu] = React.useState(true)
    
    const { t, i18n } = useTranslation();

    const tileMenu: TileMenuItem[] = [
        {
            label: t("Header.AboutUnofactor"),
            description: t("Header.AboutText"),
            image: './images/uno_logo.svg',
            href: 'http://unofactor.ru/'
        },
        {
            label: t("Header.trademark"),
            description:
                <>
                    <Text size='m' view="secondary" lineHeight='m' >Общество с ограниченной ответственностью Научно-Производственное Объединение «Союзнефтегазсервис» (ООО НПО « СНГС »)</Text>
                    <Text size='m' view="secondary" lineHeight='m'>ОГРН: 1077746375409</Text>
                    <Text size='m' view="secondary" lineHeight='m'>ИНН 7713611326 КПП 772901001</Text>
                    <Text size='m' view="secondary" lineHeight='m'>Тел. (495) 967-36 - 81</Text>
                    <Text size='m' view="secondary" lineHeight='m'>e- mail: info@nposngs.ru</Text>
                </>,
            image: './images/sngs.svg',
            href: 'http://nposngs.com/'
        },
        {
            label: t("Header.versionText"),
            description: '',
            image: '',
            href: ''
        }
    ]

    const { keycloak, initialized } = useKeycloak();
    const [userLocalInfo, setUserLocalInfo] = React.useState<UserItem>(defaultUser);
    const [CategoriesArray, setCategoriesArray] = React.useState<CategoryRow[]>([]);

    var lan = (localStorage.getItem('Lang') != null) ? JSON.parse(localStorage.getItem('Lang')) as LangItemType : langItem[0];
    for (var i = 0; i < langItem.length; i++) {
        if (lan.label == langItem[i].label)
            lan = langItem[i];
    }

    const themeItems: ThemeType[] = [
        {
            theme: presetGpnDefault,
            label: t("Theme.Light"),
            icon: IconSun,
        },
        {
            theme: presetGpnDark,
            label: t("Theme.Dark"),
            icon: IconMoon,
        },
        {
            theme: presetGpnDisplay,
            label: t("Theme.Display"),
            icon: IconLightningBolt,
        },
    ];

    const [themeValue, setThemeValue] = React.useState<ThemeType>((localStorage.getItem('mainTheme') != null) ? JSON.parse(localStorage.getItem('mainTheme')) : themeItems[0]);
    const [lang, setLang] = React.useState<LangItemType>(lan);

    //const [isLoginError, setIsLoginError] = React.useState(false);

    /*if (keycloak && initialized) {
        keycloak.onTokenExpired = () => {
            console.log('expired ' + new Date());
            keycloak.updateToken(50).then((refreshed) => {
                if (refreshed) {
                    alert("Обвноление токена: " + refreshed);
                    //console.log('refreshed ' + new Date());
                } else {
                    console.log('Токен не обновлен ' + new Date());
                }
            }).catch(() => {
                console.error('Ошибка обновления токена ' + new Date());
            });
        }
    }*/

    React.useEffect(() => {
        if (keycloak && initialized) {
            keycloak.onTokenExpired = () => {
                return keycloak.updateToken(50);
            }
            getMenuItemsFromApi("/api/get_fields");
            //getCardsArray("/api/get_cards");
        }
        return () => {
            if (keycloak) keycloak.onTokenExpired = () => { };
        };
    }, [initialized, keycloak]);

    React.useEffect(() => {
        if (keycloak && initialized) {
            keycloak.onTokenExpired = () => {
                return keycloak.updateToken(50);
            }
            getMenuItemsFromApi("/api/get_fields");
            //getCardsArray("/api/get_cards");
        }
    }, [lang])

    React.useEffect(() => {
        if (keycloak.authenticated) {
            keycloakGroups = [];
            const groups = keycloak.tokenParsed["group.list"];
            groups.map((gr) => { if (gr.includes("UnoWorld")) keycloakGroups.push(gr); })
            keycloak.loadUserInfo().then(userInfo => {
                setUserLocalInfo({
                    personName: userInfo["name"],
                    personInfo: userInfo["email"],
                    personStatus: "available",
                    personAvatarUrl: ""
                })
            });
        }
    }, [keycloak.authenticated]);

    const [searchValue, setSearchValue] = React.useState(localStorage.getItem('searchValue') != null && localStorage.getItem('searchValue') != "null" ? localStorage.getItem('searchValue') : '');
    const [visibleLogoMenu, { toogle, off }] = useFlag();
    const [favorite, setFavorite] = React.useState(localStorage.getItem('favoriteFilter') != null ?
        localStorage.getItem('favoriteFilter') == "false" ? false : true : false);
    const [publ, setPublic] = React.useState(localStorage.getItem('publicFilter') != null ?
        localStorage.getItem('publicFilter') == "false" ? false : true : false);
    const [dev, setDev] = React.useState(localStorage.getItem('devFilter') != null ?
        localStorage.getItem('devFilter') == "false" ? false : true : false);
    const [plan, setPlan] = React.useState(localStorage.getItem('planFilter') != null ?
        localStorage.getItem('planFilter') == "false" ? false : true : false);

    const [direction, setDirection] = React.useState<Direction | undefined>(undefined)
    const buttonRef = React.useRef<HTMLButtonElement>(null)

    const breakpoints = useBreakpoints({ s: 900, m: 1000, l: 1200 });

    // Для управления меню
    const [selMenuItem, setSelMenuItem] = React.useState(localStorage.getItem('selMenuItem') != null ? localStorage.getItem('selMenuItem') : (lan.lang == 'ru-RU' ? 'Агрегатор' : 'Agregate'));

    const [menuItems, setMenuItems] = React.useState<any[]>([]);
    const getMenuItemsFromApi = (url) => {
        if (!keycloak.authenticated) return;
        fetch(url, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("keycloakToken"),
                'Accept-Language': i18next.language,
                'Access-Control-Allow-Origin': '*'
            }
        }).then((response) => {
            response.json().then((data) => {
                var menuItems2 = [];
                for (var i = 0; i < data.length; i++) {
                    var menuItemName = data[i].FieldName;

                    var myName = t("Menu.Agregate");
                    if (selMenuItem == "Сервис" || selMenuItem == "Service") myName = t("Menu.Service");
                    if (selMenuItem == "Конфигурация" || selMenuItem == "Configuration") myName = t("Menu.Configuration");
                    localStorage.setItem('selMenuItem', myName);
                    if (selMenuItem != myName) setSelMenuItem(myName);

                    if (menuItemName == myName) {
                        menuItems2.push({
                            label: menuItemName,
                            active: true,
                            onClick: (e) => {
                                localStorage.setItem('selMenuItem', e.target.innerText);
                                setSelMenuItem(e.target.innerText);
                            }
                        });
                    } else {
                        menuItems2.push({
                            label: menuItemName,
                            onClick: (e) => {
                                localStorage.setItem('selMenuItem', e.target.innerText);
                                setSelMenuItem(e.target.innerText);
                            }
                        });
                    }
                }
                setMenuItems(menuItems2);
                getCardsArray("/api/get_cards");
            })
        })
    }

    const getCardsArray = (url) => {
        if (!keycloak.authenticated) return;
        fetch(url, {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem("keycloakToken"),
                'Accept-Language': i18next.language,
                'Access-Control-Allow-Origin': '*'
            }
        }).then((response) => {
            response.json().then((data) => {
                var CategoriesArrayDB = Array<CategoryRow>();
                data.map(row => {
                    CategoriesArrayDB.push({
                        Field: row.FieldName,
                        FieldIdName: row.FieldIdName,
                        Categories: row.Categories,
                        CategoriesIdName: row.CategoriesIdName,
                        Name: row.Name,
                        Description: row.Description,
                        Tags: row.Tags,
                        URL: row.Url,
                        Color: "#" + row.Color,
                        Documentation: row.Documentation,
                        Status: row.Status,
                        uidName: row.uidName,
                        RusName: row.RusName,
                        StatusColor: "#" + row.StatusColor,
                        StatusIcon: row.StatusIcon,
                        Vis: false,
                        Favorite: false
                    })
                })

                if (keycloak.authenticated && keycloakGroups.length > 0) {
                    keycloakGroups.map((group) => {
                        var arr = group.split("/");
                        // если присутствует группа 1-го уровня (/UnoWorld), тогда все карточки доступны пользователю
                        if (arr.length == 2) {
                            CategoriesArrayDB.map((cat) => cat.Vis = true);
                        }
                        // Если присутствует группа 2-го уровня (/UnoWorld/Field) тогда все карточки этой вкладки доступны пользователю
                        if (arr.length == 3) {
                            CategoriesArrayDB.map((cat) => {
                                if (cat["FieldIdName"] == arr[2])
                                    cat.Vis = true;
                            })
                        }
                        // Если присутствует группа 3-го уровня (/UnoWorld/Field/Categories) тогда все карточки этой вкладки доступны пользователю
                        if (arr.length == 4) {
                            CategoriesArrayDB.map((cat) => {
                                if (cat["FieldIdName"] == arr[2] && cat["CategoriesIdName"] == arr[3])
                                    cat.Vis = true;
                            })
                        }
                        // Прямое сопоставление карточек
                        if (arr.length == 5) {
                            CategoriesArrayDB.map((cat) => {
                                if (cat["FieldIdName"] == arr[2] && cat["CategoriesIdName"] == arr[3] && cat["uidName"] == arr[4])
                                    cat.Vis = true;
                            })
                        }
                        CategoriesArrayDB.map((cat) => {
                            const str = cat["FieldIdName"] + "/" + cat["CategoriesIdName"] + "/" + cat["uidName"];
                            if (str in favoriteList) {
                                cat.Favorite = favoriteList[str]
                            } else {
                                favoriteList[str] = false;
                            }
                            localStorage.setItem("favoriteList", JSON.stringify(favoriteList));
                        })
                    })
                }
                var CategoriesArrayDB_new = Array<CategoryRow>();
                CategoriesArrayDB.map((cat) => { if (cat.Vis) CategoriesArrayDB_new.push(cat) })
                if (!keycloak.authenticated) {
                    setCategoriesArray(CategoriesArrayDB);
                } else {
                    setCategoriesArray(CategoriesArrayDB_new);
                }
                
            })
        })
    }

    const handleLogin = () => {
        if (keycloak && !keycloak.authenticated) {
            keycloak.login();
        } else {
            visibleLogoMenu ? off() : toogle();
        }
    }

    return (
        <Theme preset={themeValue.theme} className={cnRootTheme()}>
            <div className="fullPage">
            <NavBar
                keycloak={keycloak}
                userLocalInfo={userLocalInfo}
                handleLogin={handleLogin}
                buttonRef={buttonRef}
                tileMenu={tileMenu}
                searchValue={searchValue}
                lang={lang}
                langItem={langItem}
                themeItems={themeItems}
                themeValue={themeValue}
                setLang={setLang}
                setThemeValue={setThemeValue}
                setSearchValue={setSearchValue}
                isMobile={isMobile}
                menuItems={menuItems}
                selMenuItem={selMenuItem}
                setFavorite={setFavorite}
                setPublic={setPublic}
                setDev={setDev}
                setPlan={setPlan}
                plan={plan}
                dev={dev}
                publ={publ}
                favorite={favorite}
                breakpoints={breakpoints}
            />
            <Side
              isMobile={isMobile}
              breakpoints={breakpoints}
              visibleLogoMenu={visibleLogoMenu}
              userLocalInfo={userLocalInfo}
              keycloak={keycloak}
              menuItems={menuItems}
              selMenuItem={selMenuItem}
              animateTimeout={animateTimeout}
              buttonRef={buttonRef}
              direction={direction}
              setDirection={setDirection}
              off={off}
              dev={dev}
              plan={plan}
              favorite={favorite}
              publ={publ}
              setFavorite={setFavorite}
              setPublic={setPublic}
              setDev={setDev}
              setPlan={setPlan}
            />
                <Main
                keycloak={keycloak}
                CategoriesArray={CategoriesArray}
                searchValue={searchValue}
                selMenuItem={selMenuItem}
                favorite={favorite}
                publ={publ}
                dev={dev}
                plan={plan}
                breakpoints={breakpoints}
                favoriteList={favoriteList}
                />
            </div>
        </Theme>
    )
};