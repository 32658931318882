import React from 'react';

import { cn } from '@bem-react/classname';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { cnMixSpace } from '@consta/uikit/MixSpace';

import { cnMixCard } from "@consta/uikit/MixCard";
import { Text } from '@consta/uikit/Text';
import { Sidebar } from '@consta/uikit/Sidebar';
import { FieldGroup } from '@consta/uikit/FieldGroup';
import { Card } from '@consta/uikit/Card';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { IconFavorite } from '@consta/uikit/IconFavorite';
import { IconQuestion } from '@consta/uikit/IconQuestion';
import { IconCheck } from "@consta/uikit/IconCheck";
import { IconProcessing } from "@consta/uikit/IconProcessing";
import { IconArrowRight } from "@consta/uikit/IconArrowRight";
import { IconArrowLeft } from "@consta/uikit/IconArrowLeft";
import { IconLock } from "@consta/uikit/IconLock";
import { IconPlay } from "@consta/uikit/IconPlay";
import { IconWifiOff } from '@consta/icons/IconWifiOff';

import { Badge } from '@consta/uikit/Badge';
import { Button } from '@consta/uikit/Button';
import './test.css'

const cnDescriptionUnofactor = cn('DescriptionUnofactor');

const debounce = (fn, delay) => {
    let timerId;
    return (...args) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => fn(...args), delay);
    }
};

export type Item = {
    label: string;
    content: React.ReactNode;
};

export type CategoryRow = {
    Field: string;
    FieldIdName: string,
    Categories: string;
    CategoriesIdName: string,
    Name: string;
    Description: string,
    Tags: string[],
    URL: string,
    Color: string,
    Documentation?: string,
    Status: string,
    uidName: string,
    RusName: string,
    StatusColor: string,
    StatusIcon: string,
    Vis: boolean,
    Favorite: boolean
}

export type CategoryProps<T extends CategoryRow> = {
    categoryArray: T[];
    isAuthorization?: boolean;
    filterText?: string;
    field: string;
    favorite: boolean;
    publ: boolean;
    dev: boolean;
    plan: boolean;
    favoritesList: {}
;
}

type Category = <T extends CategoryRow>(
    props: CategoryProps<T> & { ref?: React.Ref<HTMLDivElement> },
) => React.ReactElement | null;

const InternalCategory = <T extends CategoryRow>(
    props: CategoryProps<T>,
    ref: React.Ref<HTMLDivElement>
) => {
    const {
        categoryArray,
        isAuthorization = false,
        filterText = '',
        field,
        favorite,
        publ,
        dev,
        plan,
        favoritesList = {}
    } = props;

    const { t, i18n } = useTranslation();
    const [markdown, setMarkdown] = React.useState<string>('');

    /** Для кнопок вперед назад */
    const [clientWidth, setClientWidth] = React.useState({width: window.innerWidth})

    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setClientWidth({
                width: window.innerWidth
            })
        }, 1000)
        window.addEventListener('resize', debouncedHandleResize)

        categories.map((category) => {
            var str = category.Category;
            str = str.replaceAll(' ', '_');
            var visCardLength = 0;
            category.Card.map(card => {
                if (favorite) {
                    if (publ && card["Status"] == "Published" && card["Favorite"]) {
                        visCardLength++;
                    } else {
                        if (dev && card["Status"] == "Developing" && card["Favorite"]) {
                            visCardLength++;
                        } else {
                            if (plan && card["Status"] == "Planned" && card["Favorite"]) {
                                visCardLength++;
                            }
                        }
                    }
                    if (!publ && !dev && !plan && card["Favorite"]) {
                        visCardLength++;
                    }
                } else {
                    if (publ && card["Status"] == "Published") {
                        visCardLength++;
                    } else {
                        if (dev && card["Status"] == "Developing") {
                            visCardLength++;
                        } else {
                            if (plan && card["Status"] == "Planned") {
                                visCardLength++;
                            }
                        }
                    }
                }
                if (!favorite && !publ && !dev && !plan) {
                    visCardLength++;
                }
            })
            scrollBut(0, str, visCardLength);
        })

        return () => { window.removeEventListener('resize', debouncedHandleResize) }
    })
    /**************************/

    const handleClick = (url) => () => {
        if (url != "")
        {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    }


    console.log(categoryArray, 'categoryArray')

    var categories = [];
    var searchText = filterText != null ? filterText.toUpperCase() : '';
    categoryArray.map((card) => {
        if (card.Field == field)
        {
            if (searchText != '') {

                const cat = card.Categories.toUpperCase();
                const des = card.Description.toUpperCase();
                const nam = card.Name.toUpperCase();
                if (cat.includes(searchText) ||
                    des.includes(searchText) ||
                    nam.includes(searchText)
                ) {
                    var ind = categories.findIndex(el => el.Category == card.Categories);
                    if (ind == -1) {
                        categories.push({
                            Category: card.Categories,
                            Card: [card]
                        })
                    } else {
                        categories[ind].Card.push(card);
                    }
                }
            } else {
                var ind = categories.findIndex(el => el.Category == card.Categories);
                if (ind == -1) {
                    categories.push({
                        Category: card.Categories,
                        Card: [card]
                    })
                } else {
                    categories[ind].Card.push(card);
                }
            }
        }
    });

    const loadMDFile = (url, name, description) => {
        if (url != "" && url != null) {
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'text/markdown',
                },
            })
                .then((response) => {
                    return response.text()
                })
                .then((text) => {
                    setMarkdown(text);
                })
                .catch(err => {
                    setMarkdown("# " + name + "\r\n\r## " + description + "\r\n\r" + "### Невозможно загрузить описание\r\n\r" + err);
                });
        } else {
            setMarkdown("# " + name + "\r\n\r## " + description + "\r\n\r");
        }
        setVisibleDescriptMenu(true);
    }

    const CreateCard = (categoryRow) => {

                                    // {console.log(categoryRow, 'qwertyuytrewqwerty')}
        return (
            <Card style={{boxShadow: `${categoryRow["Color"] + '80'} 0px 1px 13px 0px`}} form="round" className='RulesCard' verticalSpace="s" horizontalSpace="s">
            <div className={`RulesCardBody`}>
                <div className='flip-box-inner'>
                    <div className='flip-box-front frontRules'>
                    <div className='RulesCardBodyLeft'>
                        <div className='RulesCardTextBox'>
                            <div className='RulesTitleBox'>
                                {/* <div> */}
                                    {/* <Text weight="bold" size='m'>{el?.Operation.label}</Text> */}
                                    <Text style={{ width: '248px', height: '40px', fontSize: '20px', lineHeight: '40px' }} view="primary" weight="bold" align="left" truncate>{categoryRow["Name"]}</Text>
                                {/* </div> */}
                            </div>
                        </div>
                        <div className='RulesCardinputBox'>
                            {categoryRow?.Description}
                        </div>
                        <div style={{ width: '248px', position: 'relative', top: '3px' }}>
                            {
                                categoryRow["StatusIcon"] == "IconCheck" ? 
                                    <Badge style={{ background: categoryRow["StatusColor"] }} icon={IconCheck} size="s" form="round" label={categoryRow["RusName"]} /> :
                                    categoryRow["StatusIcon"] == "IconProcessing" ?
                                        <Badge style={{ background: categoryRow["StatusColor"] }} icon={IconProcessing} size="s" form="round" label={categoryRow["RusName"]} /> :
                                        <Badge style={{ background: categoryRow["StatusColor"] }} icon={IconLock} size="s" form="round" label={categoryRow["RusName"]} />
                            
                            }
                        </div>
                    </div>
                    <div className='RulesCardBTnBox'>
                        {/* <Button 
                            // iconLeft={IconAd}
                            onlyIcon
                            iconSize='s'
                            label={t("btn.add")}
                            // onClick={() => AddRules(el)}
                            size='xs'
                            className='RulesCardBtn'
                        /> */}
                         <Button 
                            // style={{ color: 'white', marginRight: '10px' }} 
                            label="question" 
                            view="clear" 
                            iconLeft={IconQuestion} 
                            onlyIcon 
                            onClick={() => loadMDFile(categoryRow["Documentation"], categoryRow["Name"], categoryRow["Description"])} 
                         />

                        <Button
                          id={categoryRow["FieldIdName"] + "/" + categoryRow["CategoriesIdName"] + "/" + categoryRow["uidName"] + "favoriteButton"}
                          style={{ color: categoryRow["Favorite"] ? '#E7CA35' : 'gray'}}
                          label="избранное" 
                          view="clear" 
                          iconLeft={IconFavorite} 
                          iconSize='m'
                          onlyIcon
                          onClick={() => {
                              const str = categoryRow["FieldIdName"] + "/" + categoryRow["CategoriesIdName"] + "/" + categoryRow["uidName"];
                              var but = document.getElementById(str + "favoriteButton");
                              but.style.color = categoryRow["Favorite"] ? 'gray' : '#E7CA35';
                              favoritesList[str] = !favoritesList[str];
                              localStorage.setItem("favoriteList", JSON.stringify(favoritesList));
                              categoryRow["Favorite"] = !categoryRow["Favorite"];
                          }}
                        />
                        {
                            !isAuthorization ?
                                <Button
                                    style={{ color: 'black', background: 'white', float: 'right' }}
                                    label={t("Card.authorized")}
                                    view="clear"
                                    form="round"
                                /> :
                                categoryRow["Status"] == "Published" ?
                                    <Button
                                        style={{ color: 'black', background: 'white', float: 'right' }}
                                        label={t("Card.run") }
                                        view="clear"
                                        form="round"
                                        // iconRight={IconArrowRight}
                                        iconRight={IconPlay}
                                        onlyIcon
                                        onClick={handleClick(categoryRow["URL"])}
                                    /> :
                                    <Button
                                        style={{ color: 'black', background: 'white', float: 'right' }}
                                        label={t("Card.unavailable")}
                                        view="clear"
                                        iconRight={IconWifiOff}
                                        onlyIcon
                                        form="round"
                                    />
                        }
                    </div>
                    </div>
                </div>
            </div>
        </Card>



            // <div
            //     className={cnMixCard({
            //         form: "round",
            //         shadow: true,
            //     })}
            //     style={{ borderRadius: '20px', background: '#f0f0f0' }}
            // >
            //     <div>
            //         <FieldGroup form="clearClear" style={{ background: categoryRow["Color"], borderRadius: '20px 20px 0px 0px', height: '40px', verticalAlign: 'middle' }}>
            //             <Button
            //                 id={categoryRow["FieldIdName"] + "/" + categoryRow["CategoriesIdName"] + "/" + categoryRow["uidName"] + "favoriteButton"}
            //                 style={{ color: categoryRow["Favorite"] ? '#E7CA35' : 'white', marginLeft: '5px' }}
            //                 label="избранное" view="clear" iconLeft={IconFavorite} iconSize='s'
            //                 onlyIcon
            //                 onClick={() => {
            //                     const str = categoryRow["FieldIdName"] + "/" + categoryRow["CategoriesIdName"] + "/" + categoryRow["uidName"];
            //                     var but = document.getElementById(str + "favoriteButton");
            //                     but.style.color = categoryRow["Favorite"] ? 'white' : '#E7CA35';
            //                     favoritesList[str] = !favoritesList[str];
            //                     localStorage.setItem("favoriteList", JSON.stringify(favoritesList));
            //                     categoryRow["Favorite"] = !categoryRow["Favorite"];
            //                 }}
            //             />
            //             <Text style={{ width: '248px', height: '40px', color: 'white', fontSize: '20px', lineHeight: '40px' }} view="primary" weight="bold" align="left" truncate>{categoryRow["Name"]}</Text>
            //             <Button style={{ color: 'white', marginRight: '10px' }} label="question" view="clear" iconLeft={IconQuestion} onlyIcon onClick={() => loadMDFile(categoryRow["Documentation"], categoryRow["Name"], categoryRow["Description"])} />
            //         </FieldGroup>
            //         <div style={{marginTop:'5px'}}>
            //             <Text style={{ width: '248px', height: '84px', color: 'Black', fontSize: '18px', marginLeft: '18px', marginRight: '18px', overflow: 'hidden' }} align="left">{categoryRow["Description"]}</Text>
            //             <div style={{ width: '248px', marginLeft: '18px', marginRight: '18px' }}>
            //                 {
            //                     categoryRow["StatusIcon"] == "IconCheck" ? 
            //                         <Badge style={{ background: categoryRow["StatusColor"] }} icon={IconCheck} size="s" form="round" label={categoryRow["RusName"]} /> :
            //                         categoryRow["StatusIcon"] == "IconProcessing" ?
            //                             <Badge style={{ background: categoryRow["StatusColor"] }} icon={IconProcessing} size="s" form="round" label={categoryRow["RusName"]} /> :
            //                             <Badge style={{ background: categoryRow["StatusColor"] }} icon={IconLock} size="s" form="round" label={categoryRow["RusName"]} />

            //                 }
                            
            //             </div>
            //             <div style={{ height: '50px', marginLeft: '18px', marginRight: '18px' }}>
            //                 {
            //                     !isAuthorization ?
            //                         <Button
            //                             style={{ color: 'black', background: 'white', float: 'right' }}
            //                             label={t("Card.authorized")}
            //                             view="clear"
            //                             form="round"
            //                         /> :
            //                         categoryRow["Status"] == "Published" ?
            //                             <Button
            //                                 style={{ color: 'black', background: 'white', float: 'right' }}
            //                                 label={t("Card.run") }
            //                                 view="clear"
            //                                 form="round"
            //                                 iconRight={IconArrowRight}
            //                                 onClick={handleClick(categoryRow["URL"])}
            //                             /> :
            //                             <Button
            //                                 style={{ color: 'black', background: 'white', float: 'right' }}
            //                                 label={t("Card.unavailable")}
            //                                 view="clear"
            //                                 form="round"
            //                             />
            //                 }
                            
            //             </div>
            //         </div>
            //     </div>
            // </div>
        )
    }

    const [visibleDescriptMenu, setVisibleDescriptMenu] = React.useState<boolean>(false);

    const MyMarkDown = () => {
        if (visibleDescriptMenu) {
            return (
                <ReactMarkdown
                    children={markdown}
                    remarkPlugins={[remarkGfm]}
                    className="DocsDecorator"
                    components={{
                        img: ({ node, ...props }) => <img {...props} style={{ maxWidth: "100%" }} />
                    } }
                />
            );
        } else {
            return <></>
        }
    }

    const scrollBut = (step, str, visCardLength) => {
        const div = document.getElementById(str);
        if (div != null) {
            const scrollValue = div.scrollLeft;
            div.scroll({ top: 0, left: scrollValue + step, behavior: 'smooth' })
            const btL = document.getElementById(str + "Left") as HTMLButtonElement | null;
            const btR = document.getElementById(str + "Right") as HTMLButtonElement | null;
            if (btL != null) {
                if (scrollValue + step < 1)
                    btL.disabled = true;
                else
                    btL.disabled = false;
            }
            if (btR != null) {
                if (scrollValue + step >= visCardLength * 343 + (visCardLength - 1) * 32 - div.clientWidth)
                    btR.disabled = true;
                else
                    btR.disabled = false;
            }
        }
    }

    if (categories.length == 0) {
        return (
            <div style={{ marginLeft: "32px", marginRight: "32px", marginTop: "10px" }}>
                <Text size="xl" cursor="pointer" weight="bold" align="center">Доступные подсистемы платформы Унофактор отсутствуют</Text>
            </div>
        )
    }

    var visStatus = false;

    return (
        <>
        {console.log(categories)}
            {categories.map((category) => {
                var str = category.Category;
                str = str.replaceAll(' ', '_');
                var visCardLength = 0;
                category.Card.map(card => {
                    if (favorite) {
                        if (publ && card["Status"] == "Published" && card["Favorite"]) {
                            visCardLength++;
                        } else {
                            if (dev && card["Status"] == "Developing" && card["Favorite"]) {
                                visCardLength++;
                            } else {
                                if (plan && card["Status"] == "Planned" && card["Favorite"]) {
                                    visCardLength++;
                                }
                            }
                        }
                        if (!publ && !dev && !plan && card["Favorite"]) {
                            visCardLength++;
                        }
                    } else {
                        if (publ && card["Status"] == "Published") {
                            visCardLength++;
                        } else {
                            if (dev && card["Status"] == "Developing") {
                                visCardLength++;
                            } else {
                                if (plan && card["Status"] == "Planned") {
                                    visCardLength++;
                                }
                            }
                        }
                    }
                    if (!favorite && !publ && !dev && !plan) {
                        visCardLength++;
                    }
                })
                console.log(visCardLength * 343 + (visCardLength - 1) * 32 > clientWidth.width - 332)
                console.log(visCardLength, visCardLength * 343 + (visCardLength - 1) * 32, clientWidth.width - 332, clientWidth.width, )
                if (visCardLength > 0) {
                    visStatus = true;
                    return (
                        <div style={{ marginLeft: "32px", marginRight: "32px", marginTop: "10px" }}>
                            <div style={{ height: '40px', verticalAlign: 'middle', marginBottom: "10px" }}>
                                {
                                    visCardLength * 343 + (visCardLength - 1) * 32 > clientWidth.width - 332 ?
                                        <>
                                            <Text
                                                style={{ height: '30px', fontSize: '20px', lineHeight: '40px', width: 'calc(100% - 40px - 40px - 30px)', display: 'inline-block' }}
                                                view="primary" weight="bold" align="left" truncate>{category.Category.toUpperCase()}
                                            </Text>

                                            <Button
                                                id={str + "Left"}
                                                label={t("Card.back")}
                                                form="round"
                                                iconRight={IconArrowLeft}
                                                iconSize='m'
                                                size='s'
                                                onlyIcon
                                                onClick={() => { scrollBut(-375, str, visCardLength); }}
                                                className={'PrevButton'}

                                            />
                                            <Button
                                                id={str + "Right"}
                                                label={t("Card.forward")}
                                                form="round"
                                                iconLeft={IconArrowRight}
                                                iconSize='m'
                                                size='s'
                                                onlyIcon
                                                onClick={() => { scrollBut(375, str, visCardLength); }}
                                                className={'NextButton'}
                                            />
                                        </> :
                                        <Text
                                            style={{ height: '30px', fontSize: '20px', lineHeight: '40px', width: 'calc(100% - 40px - 40px - 30px)', display: 'inline-block' }}
                                            view="primary" weight="bold" align="left" truncate>{category.Category.toUpperCase()}
                                        </Text>

                                }
                            </div>
                            <div id={str} style={{ overflow: 'hidden', scrollBehavior: 'smooth' }}>
                                <div style={{ width: visCardLength * 343 + (visCardLength - 1) * 32 }}>
                                    {
                                        category.Card.map(card => {
                                            if (favorite) {
                                                if (publ && card["Status"] == "Published" && card["Favorite"]) {
                                                    return (
                                                        <div className={'divCard'}>
                                                            {CreateCard(card)}
                                                        </div>
                                                    )
                                                } else {
                                                    if (dev && card["Status"] == "Developing" && card["Favorite"]) {
                                                        return (
                                                            <div className={'divCard'}>
                                                                {CreateCard(card)}
                                                            </div>
                                                        )
                                                    } else {
                                                        if (plan && card["Status"] == "Planned" && card["Favorite"]) {
                                                            return (
                                                                <div className={'divCard'}>
                                                                    {CreateCard(card)}
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                }
                                                if (!publ && !dev && !plan && card["Favorite"]) {
                                                    return (
                                                        <div className={'divCard'}>
                                                            {CreateCard(card)}
                                                        </div>
                                                    )
                                                }
                                            } else {
                                                if (publ && card["Status"] == "Published") {
                                                    return (
                                                        <div className={'divCard'}>
                                                            {CreateCard(card)}
                                                        </div>
                                                    )
                                                } else {
                                                    if (dev && card["Status"] == "Developing") {
                                                        return (
                                                            <div className={'divCard'}>
                                                                {CreateCard(card)}
                                                            </div>
                                                        )
                                                    } else {
                                                        if (plan && card["Status"] == "Planned") {
                                                            return (
                                                                <div className={'divCard'}>
                                                                    {CreateCard(card)}
                                                                </div>
                                                            )
                                                        }
                                                    }
                                                }
                                            }
                                            if (!favorite && !publ && !dev && !plan) {
                                                return (
                                                    <div className={'divCard'}>
                                                        {CreateCard(card)}
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>)
                }
            })}
            {!visStatus ?
                <div style={{ marginLeft: "32px", marginRight: "32px", marginTop: "10px" }}>
                    <Text size="xl" cursor="pointer" weight="bold" align="center">{t("Card.missing")}</Text>
                </div> : <></>
            }
            <Sidebar
                size="3/4"
                position="bottom"
                isOpen={visibleDescriptMenu}
                onClickOutside={() => setVisibleDescriptMenu(false)}
                onClose={() => setVisibleDescriptMenu(false)}
            >
                <div className={cnDescriptionUnofactor({},[cnMixSpace({ p: 'xl' })])}>
                    <MyMarkDown />
                </div>
            </Sidebar>
        </>
    )
}

export const Category = React.forwardRef(InternalCategory) as Category;