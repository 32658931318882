import React from 'react'
import { Category, CategoryRow } from '../Categories';
import { Text } from '@consta/uikit/Text';

export default function Main({breakpoints, keycloak, CategoriesArray, searchValue, selMenuItem, favorite, publ, dev, plan, favoriteList}) {
	return (
		<div style={{ position: "absolute", left: (breakpoints.m || breakpoints.l) ? "260px" : "0", right: "0px", top: '60px', bottom: "0px", overflow: 'auto' }}>
                    {
                        keycloak.authenticated ?
                            <div>
                                <Category
                                    categoryArray={CategoriesArray}
                                    isAuthorization={keycloak.authenticated}
                                    filterText={searchValue}
                                    field={selMenuItem}
                                    favorite={favorite}
                                    publ={publ}
                                    dev={dev}
                                    plan={plan}
                                    favoritesList={favoriteList}
                                />
                            </div> :
                            <div style={{ position: "relative", top: "60px", overflow: "auto", height: "calc(100% - 60px)", textAlign: 'center' }}>
                                <Text size="l" weight="bold">Необходимо авторизоваться</Text>
                            </div>

                        }
                    </div>
	)
}
