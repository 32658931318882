import React, { SVGProps } from 'react';
import { createIcon } from '@consta/icons/Icon';

const IconRusSizeB = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 101.527" {...props}>
        <g>
            <path d="M9.942,0h102.986c2.727,0,5.213,1.118,7.016,2.918c1.811,1.808,2.936,4.294,2.936,7.023v81.643 c0,2.729-1.123,5.217-2.935,7.026c-1.802,1.799-4.286,2.916-7.017,2.916H9.942c-2.72,0-5.202-1.117-7.009-2.918l-0.024-0.023 C1.113,96.779,0,94.302,0,91.585V9.942c0-2.72,1.117-5.202,2.917-7.009l0.024-0.024C4.749,1.112,7.226,0,9.942,0L9.942,0z M85.345,33.372h10.747v20.735c0,2.048-0.328,3.994-0.964,5.817c-0.636,1.833-1.646,3.423-3.012,4.798 c-1.365,1.366-2.806,2.329-4.303,2.882c-2.085,0.767-4.602,1.159-7.528,1.159c-1.693,0-3.545-0.121-5.537-0.355 c-2.002-0.234-3.676-0.701-5.022-1.412c-1.348-0.702-2.572-1.702-3.686-2.993c-1.122-1.3-1.88-2.638-2.291-4.013 c-0.664-2.207-1.001-4.171-1.001-5.883V33.372h10.746v21.222c0,1.898,0.523,3.376,1.571,4.442c1.058,1.066,2.507,1.609,4.378,1.609 c1.842,0,3.292-0.524,4.34-1.581c1.048-1.048,1.571-2.534,1.571-4.461V33.372H85.345L85.345,33.372z M26.789,68.174V33.372h17.92 c3.321,0,5.864,0.28,7.614,0.86c1.758,0.57,3.18,1.627,4.255,3.17c1.076,1.543,1.609,3.433,1.609,5.659 c0,1.927-0.412,3.602-1.244,4.995c-0.813,1.412-1.955,2.544-3.404,3.422c-0.917,0.553-2.18,1.011-3.779,1.376 c1.282,0.43,2.208,0.851,2.797,1.28c0.393,0.281,0.963,0.898,1.721,1.834c0.749,0.935,1.244,1.655,1.497,2.16l5.228,10.055h-12.14 l-5.743-10.615c-0.729-1.375-1.375-2.273-1.945-2.685c-0.776-0.533-1.665-0.805-2.637-0.805h-0.945v14.095H26.789L26.789,68.174 L26.789,68.174z M37.582,47.513h4.546c0.486,0,1.439-0.159,2.852-0.477c0.711-0.141,1.301-0.506,1.74-1.095 c0.458-0.589,0.683-1.263,0.683-2.029c0-1.123-0.355-1.993-1.066-2.591c-0.711-0.608-2.048-0.907-4.021-0.907h-4.733V47.513 L37.582,47.513L37.582,47.513z M112.929,7.95H9.942c-0.555,0-1.052,0.22-1.404,0.572L8.522,8.538 C8.169,8.89,7.951,9.387,7.951,9.942v81.643c0,0.555,0.219,1.053,0.572,1.404l0.016,0.016c0.352,0.353,0.85,0.572,1.404,0.572 h102.986c0.561,0,1.065-0.225,1.428-0.586c0.354-0.354,0.573-0.852,0.573-1.406V9.942c0-0.552-0.221-1.05-0.575-1.403 C113.991,8.175,113.485,7.95,112.929,7.95L112.929,7.95z" />
	    </g>
    </svg>
)

const IconRusSizeColor = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.32 38.52">
        <g>
            <path style={{ fill: '#FFFFFF' }} d="M3.09,0.06h49.13c1.67,0,3.03,1.36,3.03,3.03v16.17H0.06V3.09C0.06,1.42,1.42,0.06,3.09,0.06L3.09,0.06z" />
            <path style={{ fill: '#D52B1E' }} d="M0.06,19.26h55.2v16.17c0,1.67-1.36,3.03-3.03,3.03H3.09c-1.67,0-3.03-1.37-3.03-3.03V19.26L0.06,19.26z" />
            <polygon style={{ fill: '#0039A6' }} points="0.06,12.86 55.26,12.86 55.26,25.66 0.06,25.66 0.06,12.86" />
            <path style={{ fill: 'none', stroke: '#CCCCCC', strokeWidth: '0.1199', strokeMiterlimit: '2.6131' }} d="M3.09,0.06h49.13c1.67,0,3.03,1.36,3.03,3.03v32.33c0,1.67-1.36,3.03-3.03,3.03H3.09 c-1.67,0-3.03-1.37-3.03-3.03V3.09C0.06,1.42,1.42,0.06,3.09,0.06L3.09,0.06z" />
        </g>
    </svg>
)

export const IconRus = createIcon({
    l: IconRusSizeB,
    m: IconRusSizeB,
    s: IconRusSizeB,
    xs: IconRusSizeB,
    name: 'IconRus',
    renderType: {
        l: 'use',
        m: 'use',
        s: 'default',
        xs: 'use',
    },
    color: 'multiple',
});