import React from 'react';

export const SNGSLogo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" height={52} fill="var(--color-bg-brand)" {...props}>
        <rect width="52" height="52" />
        <polygon points="27.8,27.3 46.7,27.3 46.7,32.9 34,32.9 34,41 46.7,41 46.7,46.6 27.8,46.6 " fill={"#FFFFFF"} />
        <polygon points="4.1,3.4 23,3.4 23,9 10.3,9 10.3,17.1 23,17.1 23,22.7 4.1,22.7 " fill={"#FFFFFF"} />
        <polygon points="27.7,3.4 34,3.4 34,10.3 40.5,10.3 40.5,3.4 46.7,3.4 46.7,22.7 40.5,22.7 40.5,15.9 34,15.9 34,22.7 27.7,22.7 " fill={"#FFFFFF"} />
        <polygon points="4,27.3 23,27.3 23,32.9 10.3,32.9 10.3,46.6 4,46.6 " fill={"#FFFFFF"} />
    </svg>
)
